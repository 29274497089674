import { ImageBackground, StyleSheet, Text, View } from 'react-native'

const styles = StyleSheet.create({
	title: {
		color: 'white',
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 'auto',
		marginBottom: 'auto',
		overflow: 'hidden'
	},
	secondTitle: {
		color: '#F4F4F4',
		fontSize: 10,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 'auto',
		marginBottom: 'auto',
		overflow: 'hidden',
	}
})

export default function Card({ image, title, secondTitle = '' }) {
	const fallback = require('../../../assets/fallback_cat.png')

	return (
		<View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<ImageBackground
				source={image ? { uri: image } : fallback}
				resizeMode="cover"
				blurRadius={image ? 0 : 10}
				imageStyle={{ borderRadius: 15 }}
				style={{ height: 120, width: 120 }}
			>
			</ImageBackground>
			<View style={{ width: 100 }}>
				<Text style={styles.title} ellipsizeMode={'tail'}
					numberOfLines={1}>{title}</Text>
				{secondTitle !== '' && <Text style={styles.secondTitle} ellipsizeMode={'tail'}
					numberOfLines={1}>{secondTitle}</Text>}
			</View>
		</View>
	)
}
