import { Text } from 'react-native'


export const Heading = ({ children, style, ...props }) => {
	return (
		<Text
			style={{
				fontSize: 24,
				fontWeight: 'bold',
				color: 'white',
				...style
			}}
			{...props}
		>
			{children}
		</Text>
	)
}

export const NewItemLabelText = ({ children, fontSize = 18, fontColor = 'yellow', style, ...props }) => {
	return (
		<Text
			style={{
				fontSize: fontSize,
				color: fontColor,
				...style
			}}
			{...props}
		>
			{children}
		</Text>
	)
}