import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Sentry from 'sentry-expo'

const useTokens = () => {
	const getTokens = async () => {
		let token
		if (Platform.OS === 'web') {
			token = JSON.parse(localStorage.getItem('token'))
			return token
		} else {
			try {
				token = JSON.parse(await AsyncStorage.getItem('token'))
				return token
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
		}
	}

	const setTokens = async token => {
		if (Platform.OS === 'web') {
			localStorage.setItem('token', JSON.stringify(token))
		} else {
			try {
				await AsyncStorage.setItem('token', JSON.stringify(token))
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
		}
	}

	const getUser = async () => {
		if (Platform.OS === 'web') {
			return JSON.parse(localStorage.getItem('user'))
		} else {
			try {
				return JSON.parse(await AsyncStorage.getItem('user'))
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
		}
	}
	return { getTokens, setTokens, getUser }
}

export default useTokens
