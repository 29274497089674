import Checkbox from 'expo-checkbox'
import React, { useEffect, useState } from 'react'
import { FlatList, Pressable, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import { CheckboxTicker } from './CheckboxTicker'

export const CheckListbox = ({
	listboxItems,
	setListboxItems,
	hasSelectAll = false,
	hasListboxSelectedItems = false,
	refreshListboxSelectedItems,
	title,
	handleRemoveAll,
	isSelectAllChecked,
	setSelectAllChecked,
	emptyLabel = 'Ingen spillelister'
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	const styles = StyleSheet.create({
		main: {
			width: '50%', 
			height: '80%',
			minHeight: '80%'
		},
		container: {
			flex: 1,
			marginLeft: isWideScreen ? 15 : 10,
			marginRight: isWideScreen ? 10 : 5,
			color: 'white',
			backgroundColor: colors.primary,
			marginTop: isWideScreen ? 0 : 5,
		},
		title: {
			color: 'white',
			marginLeft: isWideScreen ? 20 : 10,
			fontSize: 16
		},
		section: {
			marginTop: 5,
			marginBottom: 10,
			marginLeft: isWideScreen ? 10 : 5,
			flexDirection: 'row',
			alignItems: 'center'
		},
		paragraph: {
			fontSize: 15,
			color: 'white'
		},
		checkbox: {
			margin: 10
		}
	})
	
	const handleCheckboxClick = (item, isChecked) => {
		let selectedItem = listboxItems.filter(x => x.id === item.id)[0]
		selectedItem.isChecked = !isChecked
		let listboxItemsTemp = listboxItems.filter(x => x.id !== item.id)
		listboxItemsTemp.push(selectedItem)
		listboxItemsTemp = listboxItemsTemp.sort(function (a, b) {
			return a.id - b.id
		})
		setListboxItems(listboxItemsTemp)
		if (hasListboxSelectedItems) refreshListboxSelectedItems(item, !isChecked)
	}

	const handleSelectAll = () => {
		let listboxItemsTemp = []
		let isCheckedTemp = !isSelectAllChecked
		if (isCheckedTemp === true) {
			listboxItemsTemp = listboxItems.filter(x => (x.isChecked = isCheckedTemp))
			setListboxItems(listboxItemsTemp)
			listboxItemsTemp.map(item => {
				refreshListboxSelectedItems(item, isCheckedTemp)
			})
			setSelectAllChecked(isCheckedTemp)
		} else {
			handleRemoveAll()
		}
	}

	return (
		<View style={styles.main}>
			<View>
				<Text style={styles.title}>{title}</Text>
			</View>
			{hasSelectAll && (
				<Pressable
					onPress={() => {
						handleSelectAll()
					}}
				>
					<View style={styles.section}>
						<Checkbox
							style={styles.checkbox}
							value={isSelectAllChecked}
							color={isSelectAllChecked ? colors.accent : undefined}
						/>
						<Text style={styles.paragraph}>{isSelectAllChecked ? 'Fravælg alle' : 'Vælg alle'}</Text>
					</View>
				</Pressable>
			)}
			<ScrollView style={styles.container}>
				<FlatList
					data={listboxItems}
					ListEmptyComponent={
						<View>
							<Text style={{ color: colors.accent, margin: isWideScreen ? 10 : 5, fontSize: isWideScreen ? 20 : 18 }}>{emptyLabel}...</Text>
						</View>
					}
					renderItem={({ item }) => (
						<CheckboxTicker
							isChecked={item.isChecked}
							label={item.username ? item.username : item.name}
							handleCheckboxClick={handleCheckboxClick}
							item={item}
							style={{ fontSize: isWideScreen ? 15 : (isSmallScreen ? 10 : 12) }}
							checkboxMargin={isSmallScreen ? 3 : 5}
							paragraphHeight={isWideScreen ? 30 : 20}
						/>
					)}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					keyExtractor={item => item.id}
				/>
			</ScrollView>
		</View>
	)
}
