import { logException } from '../sentryLog'

export const createContainersTable = tx => {
	tx.executeSql(
		'CREATE TABLE IF NOT EXISTS containers ( id integer PRIMARY KEY NOT NULL, Photo text NOT NULL, description text, name text NOT NULL, department integer NOT NULL, lastCached integer DATETIME DEFAULT CURRENT_TIMESTAMP not null);',
		[],
		() => null,
		(_, e) => logException(e)
	)
}

export const createPlaylistsTable = tx => {
	tx.executeSql(
		'CREATE TABLE IF NOT EXISTS playlists ( id integer PRIMARY KEY NOT NULL, playlist integer NOT NULL, container_group integer NOT NULL, description text, playlist_name text NOT NULL, position integer, Photo text NOT NULL, lastCached integer DATETIME DEFAULT CURRENT_TIMESTAMP not null, music_therapist integer NOT NULL, music_therapist_name text);',
		[],
		() => null,
		(_, e) => logException(e)
	)
}

export const createTracksTable = tx => {
	tx.executeSql(
		'CREATE TABLE IF NOT EXISTS tracks ( id INTEGER PRIMARY KEY NOT NULL, playlist INTEGER NOT NULL, position INTEGER NOT NULL, refreshUrl text NOT NULL, track TEXT NOT NULL, lastCached integer DATETIME DEFAULT CURRENT_TIMESTAMP not null);',
		[],
		() => null,
		(_, e) => logException(e)
	)
}

export const createUserPlaylistsTable = tx => {
	tx.executeSql(
		'CREATE TABLE IF NOT EXISTS userPlaylists (id INTEGER PRIMARY KEY NOT NULL, name TEXT NOT NULL, user INTEGER NOT NULL, visibility INTEGER, Photo TEXT, description TEXT, theme TEXT, lastCached integer DATETIME DEFAULT CURRENT_TIMESTAMP not null)',
		[],
		() => null,
		(_, e) => logException(e)
	)
}

export const createGuidedTourTagging = tx => {
	tx.executeSql(
		'CREATE TABLE IF NOT EXISTS guidedTourTagging (isAlreadyShown TEXT NOT NULL, username TEXT NOT NULL)',
		[],
		() => null,
		(_, e) => logException(e)
	)
}

export const createCategoriesTable = tx => {
	tx.executeSql(
		'CREATE TABLE IF NOT EXISTS categories ( id integer PRIMARY KEY NOT NULL, Photo text NOT NULL, description text, name text NOT NULL, department integer NOT NULL, lastCached integer DATETIME DEFAULT CURRENT_TIMESTAMP not null, position integer NOT NULL);',
		[],
		() => null,
		(_, e) => logException(e)
	)
}