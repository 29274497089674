import React, { useState } from 'react'

import axios from 'axios'
import { Alert, ScrollView, Text, useWindowDimensions } from 'react-native'
import base64 from 'react-native-base64'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { Input, TextArea } from '../../Components/Forms/Input'
import { SONGREQUEST } from '../../Helpers/variables'
import isEmailValid from '../../Helpers/validateFields'
import useAxios from '../../Hooks/useAxios'
import { Platform } from 'react-native'

export default function SongRequestScreen() {
	const [artistInput, setArtistInput] = useState('')
	const [albumInput, setAlbumInput] = useState('')
	const [titleInput, setTitleInput] = useState('')
	const [emailInput, setEmailInput] = useState('')
	const [messageInput, setMessageInput] = useState('')
	const [loading, setLoading] = useState(false)
	const windowHeight = useWindowDimensions().height
	const [errorMessage, setErrorMessage] = useState('')
	const api = useAxios()
	const descriptionMaxLength = 100

	const createJiraIssue = () => {
		const bodyData = {
			fields: {
				project: {
					key: 'LFA'
				},
				summary: 'TEST 99',
				description: 'REST APIs are great.',
				issuetype: {
					name: 'Bug'
				},
				sprint: {
					name: 'LFA Sprint 1'
				}
			}
		}

		axios
			.post(
				'https://www.musicmind-cors.dk/https://auditive.atlassian.net/rest/api/2/issue/',
				JSON.stringify(bodyData),
				{
					headers: {
						Authorization: `Basic${base64.encode('annborreo@instorex.dk:THD87cBueEnrXTUGtosPF094').toString('base64')}`,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						'X-Atlassian-Token': 'no-check'
					}
				}
			)
			.then(response => {
				console.log(response.status)
				if (response.status === 201) {
					console.log('success: ' + item.name)
				} else {
					console.log('failed: ' + item.name)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true)
		// createJiraIssue()

		if (errorMessage !== '') return

		if (artistInput === '' || albumInput === '' || titleInput === '' || messageInput === '' || emailInput === '') {
			setErrorMessage('Alle input er pøkrøvet')
			setLoading(false)
			return
		}

		if (!isEmailValid(emailInput)) {
			setErrorMessage('E-mailen er ugyldig')
			setLoading(false)
			return
		}

		try {
			let artist = artistInput
			let album = albumInput
			let track = titleInput
			let message = messageInput
			let email = emailInput

			let { status: status } = await api.post(SONGREQUEST, {
				artist,
				album,
				track,
				message,
				email
			})

			if (status === 201) {
				if (Platform.OS === 'web') alert('Email sendt!')
				else Alert.alert('Anmod om en sang', 'Email sendt!', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				setAlbumInput('')
				setArtistInput('')
				setTitleInput('')
				setMessageInput('')
				setEmailInput('')
			} else {
				if (Platform.OS === 'web') alert('Kunne ikke sende e-mail!')
				else
					Alert.alert('Anmod om en sang', 'Kunne ikke sende e-mail!', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
			}
		} catch (err) {
			console.log(err)
			Alert.alert('Anmod om en sang', 'Kunne ikke sende e-mail!', [
				{ text: 'OK', onPress: () => console.log('OK Pressed') }
			])
		} finally {
			setLoading(false)
			setErrorMessage('')
		}
	}

	return (
		<ScrollView style={{ maxHeight: windowHeight - 100, marginLeft: 10 }}>
			<Input
				style={{ marginTop: 10 }}
				onChangeText={text => setEmailInput(text)}
				placeholder={'E-mail'}
				value={emailInput}
			/>
			<Input onChangeText={text => setArtistInput(text)} placeholder={'Kunstner'} value={artistInput} />
			<Input
				style={{ marginTop: 10 }}
				onChangeText={text => setAlbumInput(text)}
				placeholder={'Album'}
				value={albumInput}
			/>
			<Input
				style={{ marginTop: 10 }}
				onChangeText={text => setTitleInput(text)}
				placeholder={'Sangtitel'}
				value={titleInput}
			/>
			<TextArea
				style={{ marginTop: 10 }}
				onChangeText={text => {
					if (text.length > descriptionMaxLength) {
						let errorDesc = 'Beskeden må maksimalt være på ' + descriptionMaxLength + ' tegn'
						// alert(errorDesc)
						// setMessageInput(text.slice(0, descriptionMaxLength))
						setErrorMessage(errorDesc)
						return
					} else {
						setMessageInput(text)
						setErrorMessage('')
					}
				}}
				placeholder={'Besked'}
				value={messageInput}
				numberOfLines={4}
				// maxLength={descriptionMaxLength}
			/>
			<Text style={{ color: 'white', textAlign: 'right' }}>{messageInput.length + '/' + descriptionMaxLength}</Text>
			<Text style={{ fontSize: 14, color: 'red' }}>{errorMessage}</Text>
			<PrimaryButton onPress={handleFormSubmit} title={'Send'} loading={loading} />
		</ScrollView>
	)
}
