import * as SQLite from 'expo-sqlite'
import { Platform } from 'react-native'
import {
	createContainersTable,
	createPlaylistsTable,
	createTracksTable,
	createUserPlaylistsTable,
	createGuidedTourTagging,
	createCategoriesTable
} from './database/createTables'

const openDatabase = () => {
	if (Platform.OS === 'web') {
		return {
			transaction: () => {
				return {
					executeSql: () => {}
				}
			}
		}
	}

	const db = SQLite.openDatabase('db.db')
	db.exec([{ sql: 'PRAGMA foreign_keys = ON;', args: [] }], false, () =>
		console.log('Database connection established with foreign_keys')
	)

	return db
}

const db = openDatabase()

export const dropAllTables = () => {
	db.transaction(tx => {
		tx.executeSql('DROP TABLE IF EXISTS containers;')
		tx.executeSql('DROP TABLE IF EXISTS playlists;')
		tx.executeSql('DROP TABLE IF EXISTS tracks;')
		tx.executeSql('DROP TABLE IF EXISTS userPlaylists;')
		tx.executeSql('DROP TABLE IF EXISTS guidedTourTagging;')
		tx.executeSql('DROP TABLE IF EXISTS categories;')
	})
}

export const dropSingleTable = table => {
	db.transaction(tx => {
		tx.executeSql('DROP TABLE IF EXISTS ' + table)
	})
}

export const createDatabaseOnFirstMount = () => {
	dropSingleTable('guidedTourTagging')
	db.transaction(tx => {
		createContainersTable(tx)
		createPlaylistsTable(tx)
		createTracksTable(tx)
		createUserPlaylistsTable(tx)
		createGuidedTourTagging(tx)
		createCategoriesTable(tx)
	})
}

export default db
