import { useState } from 'react'
import { FlatList, Keyboard, Text, TouchableHighlight, TouchableWithoutFeedback, View } from 'react-native'
import { MenuDivider } from 'react-native-material-menu'
import { Menu, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu'
import { colors } from '../../Helpers/variables'
import { Input } from '../Forms/Input'

export const SearchableSelectMenu = ({
	dataSource,
	handleDropdownClick,
	placeholder,
	searchInput,
	handleChange,
	setSearchInput,
	style
}) => {
	const [isOpened, setIsOpened] = useState(false)
	// const [searchInput, setSearchInput] = useState(newValue)
	const [filteredData, setFilteredData] = useState(dataSource)

	const changeText = (text, isFocus = false) => {
		if (!text) text = ''
		if (dataSource.length > 0) setSearchInput(text)
		if (text !== '') {
			const temp = text.toLowerCase()
			let tempList = dataSource.filter(item => {
				if (item.name.toLowerCase() === temp) return item
			})

			if (tempList.length === 0) {
				tempList = dataSource.filter(item => {
					if (item.name.toLowerCase().startsWith(temp)) return item
				})
				setFilteredData(tempList)
				if (tempList.length === 0) setIsOpened(false)
				else setIsOpened(true)
			} else {
				setFilteredData(dataSource)
				setIsOpened(true)
			}
		} else {
			setFilteredData(dataSource)
			setIsOpened(false)
		}
	}

	return (
		<TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
			<Menu
				opened={isOpened}
				onBackdropPress={() => {
					setIsOpened(false)
				}}
				renderer={renderers.Popover}
				placement="bottom"
			>
				<MenuTrigger>
					<Input
						onChangeText={text => {
							changeText(text)
							handleChange(text)
						}}
						value={searchInput}
						placeholder={placeholder}
						style={{ ...style }}
						maxLength={100}
						// handleOnFocus={() => {
						// 	changeText(searchInput, true)
						// }}
					/>
				</MenuTrigger>
				<MenuOptions
					style={{
						backgroundColor: colors.primary,
						padding: 5,
						color: 'white',
						borderRadius: 5
					}}
					optionsContainerStyle={{
						marginTop: 43,
						width: 300,
						maxHeight: 150,
						overflow: 'visible',
						borderColor: 'white',
						borderWidth: 0.4,
						borderRadius: 5
					}}
				>
					{filteredData.length > 0 && (
						<FlatList
							data={filteredData}
							ListEmptyComponent={
								<View>
									<Text style={{ color: 'white', margin: 10, fontSize: 17 }}>Ingen data...</Text>
								</View>
							}
							renderItem={({ item }) => (
								<TouchableHighlight
									onPress={() => {
										handleDropdownClick(item.name)
										setSearchInput(item.name)
										setIsOpened(false)
									}}
								>
									<View>
										<View style={{ flexDirection: 'row' }}>
											<Text style={{ color: 'white', margin: 7, fontSize: 17 }}>{item.name}</Text>
											{item.subTitle && (
												<Text style={{ color: 'white', margin: 14, fontSize: 10 }}>{item.subTitle}</Text>
											)}
										</View>
										{filteredData.length > 1 && <MenuDivider color="gray" />}
									</View>
								</TouchableHighlight>
							)}
							keyExtractor={item => item.name}
						/>
					)}
				</MenuOptions>
			</Menu>
		</TouchableWithoutFeedback>
	)
}
