import { Ionicons } from '@expo/vector-icons'
import * as Network from 'expo-network'
import { Platform, Pressable, Text, View, useWindowDimensions } from 'react-native'
import * as Sentry from 'sentry-expo'

import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { colors } from '../../Helpers/variables'
import useLayout from '../../Hooks/useLayout'
import OfflineBar from './OfflineBar'
import OnlineBar from './OnlineBar'
import SubHeader from './SubHeader'
import { CopilotProvider } from 'react-native-copilot'

const Header = ({ title }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const navigation = useNavigation()
	const { setOfflineOnlineStatus, isOffline, deviceType } = useLayout()
	const [showOnlineBar, setShowOnlineBar] = useState(false)
	const route = useRoute();

	useEffect(() => {
		async function boot() {
			try {
				const { isInternetReachable } = await Network.getNetworkStateAsync()
				setOfflineOnlineStatus(!isInternetReachable)
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
				setOfflineOnlineStatus(true)
			}
		}
		boot()
	}, [])

	useEffect(() => {
		if (isOffline) {
			let timeoutId = setInterval(async () => {
				const { isInternetReachable } = await Network.getNetworkStateAsync()
				if (isInternetReachable) {
					setShowOnlineBar(true)
					clearInterval(timeoutId)
					setOfflineOnlineStatus(!isInternetReachable)
				}
			}, 5000)
		}
	}, [isOffline])

	return (
		<View>
			{isOffline && (
				<View>
					<OfflineBar />
				</View>
			)}

			{showOnlineBar && (
				<View>
					<OnlineBar setShowOnlineBar={setShowOnlineBar} />
				</View>
			)}
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					backgroundColor: colors.primary,
					paddingVertical: 10,
					height: 50
				}}
			>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{(navigation.canGoBack() && route.name !== 'HomeScreen') && (
						<Pressable onPress={() => { return navigation.goBack() }}>
							<Ionicons name="arrow-back" size={isWideScreen ? 26 : 22} style={{ marginRight: 10 }} color="white" />
						</Pressable>
					)}

					{deviceType !== 1 ? <Text style={{ color: 'white', fontSize: 20 }}>{title}</Text> : null}
				</View>
				<View style={{ justifyContent: 'flex-end', marginLeft: (navigation.canGoBack() && route.name !== 'HomeScreen') ? -10 : 0 }}>
					<CopilotProvider
						animated={true} // Can be true or false
						overlay={'view'} // Can be either view or svg
						labels={{ previous: 'Tidligere', next: 'Næste', skip: 'Springe', finish: 'Afslut' }}
						backdropColor={'rgba(82, 82, 82, 0.9)'}
						androidStatusBarVisible={true}
					>
						<SubHeader />
					</CopilotProvider>
				</View>
			</View>
		</View>
	)
}

export default React.memo(Header)
