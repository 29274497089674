import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { Pressable, Text, TouchableHighlight, View } from 'react-native'
import PlayButton from '../../../Components/Layouts/Sidebar/components/TrackControls/PlayButton'
import truncateStr from '../../../Helpers/truncateStr'
import { colors } from '../../../Helpers/variables'
import useLayout from '../../../Hooks/useLayout'
import useSound from '../../../Hooks/useSound'
import { trackStyles } from './TrackStyles'

const TrackLabels = ({ track, isQueue, tracksList, trackIndex, playlistTrackId }) => {
	const { deviceType } = useLayout()
	const {
		handleTrackPlayer,
		currentPlayingTrack,
		handleClickRepeat,
		repeatType,
		trackList,
		setIsShuffleActive,
		isRepeatAllTracks,
		sound
	} = useSound()

	const [playing, setPlaying] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const navigation = useNavigation()

	const handlePressTrack = () => {
		if (isRepeatAllTracks && repeatType === 'none') handleClickRepeat('repeatAllTracks')
		else if (!isRepeatAllTracks && repeatType === 'repeatAllTracks') handleClickRepeat('none')
		if (isQueue) {
			let newIndex = trackList.findIndex(x => x.id === playlistTrackId)
			handleTrackPlayer(trackList[newIndex].track, trackList, newIndex)
		} else {
			if (trackList.length > 0) {
				if (!tracksList[0].playlist && !trackList[0].playlist) {
					handleTrackPlayer(tracksList[trackIndex].track, tracksList, trackIndex)
					setIsShuffleActive(false)
					return
				}
				if (tracksList[0].playlist === trackList[0].playlist) {
					let newIndex = trackList.findIndex(x => x.id === tracksList[trackIndex].id)
					handleTrackPlayer(trackList[newIndex].track, trackList, newIndex)
				} else {
					handleTrackPlayer(tracksList[trackIndex].track, tracksList, trackIndex)
					setIsShuffleActive(false)
				}
			} else {
				handleTrackPlayer(tracksList[trackIndex].track, tracksList, trackIndex)
				setIsShuffleActive(false)
			}
		}
	}

	const initialLoad = useCallback(async () => {
		setPlaying(currentPlayingTrack?.id === track?.id)
		setLoaded(currentPlayingTrack?.id === track?.id)
	}, [currentPlayingTrack?.id, track?.id])

	useEffect(() => {
		initialLoad()
	}, [currentPlayingTrack])

	return (
		<View style={{ flexDirection: 'row', flex: 12 }}>
			<Pressable
				onPress={() => {
					handlePressTrack()
				}}
				style={trackStyles.songContainer}
			>
				<View style={{ flexDirection: 'row' }}>
					{navigation && (
						<View style={{ marginRight: 10 }}>
							<PlayButton size={30} track={track} handlePressTrack={handlePressTrack} />
						</View>
					)}
					<View style={{ width: '40%' }}>
						<Text
							style={{
								color: playing || loaded ? colors.accent : 'white',
								fontSize: 14
							}}
						>
							{truncateStr(track.song_title, 45)}
						</Text>
						<TouchableHighlight
							onPress={() => {
								if (navigation)
									navigation.navigate('ArtistTracks', {
										artistId: track.artist,
										photo: track.album_photo,
										title: track.artist_name
									})
							}}
						>
							<View style={{ width: '80%' }}>
								<Text style={trackStyles.albumTitle}>{truncateStr(track.artist_name, 45)}</Text>
							</View>
						</TouchableHighlight>
					</View>
					{deviceType !== 1 && (
						<View style={{ width: '40%' }}>
							<Pressable onPress={() => handlePressTrack()} style={trackStyles.albumContainer}>
								<TouchableHighlight
									onPress={() => {
										if (navigation)
											navigation.navigate('AlbumTracks', {
												albumId: track.album,
												photo: track.album_photo,
												title: track.album_name
											})
									}}
								>
									<View>
										<Text
											style={{
												color: playing || loaded ? colors.accent : 'white',
												fontSize: 13
											}}
										>
											{truncateStr(track.album_name, 45)}
										</Text>
									</View>
								</TouchableHighlight>
							</Pressable>
						</View>
					)}
				</View>
			</Pressable>
		</View>
	)
}

export default React.memo(TrackLabels)
