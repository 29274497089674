import { Ionicons } from '@expo/vector-icons'
import { useEffect, useState } from 'react'
import { Pressable } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import usePlaybackStatus from '../../../../../Hooks/usePlaybackStatus'
import useSound from '../../../../../Hooks/useSound'

export default function PlayButton({ size = 55, track, handlePressTrack = undefined }) {
	const { currentPlayingTrack, handleTrackPlayer } = useSound()
	const { playbackStatus } = usePlaybackStatus()
	const [playing, setPlaying] = useState(false)

	useEffect(() => {
		if (currentPlayingTrack && track) {
			if (currentPlayingTrack.id === track.id) setPlaying(playbackStatus.isPlaying)
			else setPlaying(false)
		} else {
			setPlaying(playbackStatus.isPlaying)
		}
	}, [playbackStatus.isPlaying])

	const renderButton = () => {
		if (!playing)
			return (
				<Ionicons
					name="play-circle"
					size={size}
					color={currentPlayingTrack === null ? colors.secondary : colors.accent}
				/>
			)
		return (
			<Ionicons
				name="pause-circle"
				size={size}
				color={currentPlayingTrack === null ? colors.secondary : colors.accent}
			/>
		)
	}
	return (
		<Pressable
			disabled={currentPlayingTrack === null}
			onPress={() => {
				if (handlePressTrack) handlePressTrack()
				else handleTrackPlayer(currentPlayingTrack)
			}}
		>
			{renderButton()}
		</Pressable>
	)
}
