/* ----------------------------- Library Imports ---------------------------- */
import { NavigationContainer } from '@react-navigation/native'
import { Suspense, useEffect, useState } from 'react'
import { Platform, StatusBar, UIManager, View } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import { MenuProvider } from 'react-native-popup-menu'
import * as Sentry from 'sentry-expo'

/* ----------------------------- Context Imports ---------------------------- */
import { AuthContext } from './Context/AuthContext/AuthContext'
import { LayoutProvider } from './Context/LayoutContext/LayoutContext'
import { SoundProvider } from './Context/SoundContext/SoundContext'

/* ------------------------------ Hook imports ------------------------------ */

/* ---------------------------- Database Imports ---------------------------- */
import { createDatabaseOnFirstMount } from './Helpers/database'
import { captureException } from './Helpers/sentryLog'

/* ---------------------------- Variable Imports ---------------------------- */
import { colors, SENTRY_DSN, USERTYPE_URL } from './Helpers/variables'

/* -------------------------- Screen/Stack Imports -------------------------- */
import Loader from './Components/Loader/Loader'
import AdminMainStack from './Components/Stacks/AdminMainStack'
import MainStack from './Components/Stacks/MainStack'
import RootStack from './Components/Stacks/RootStack'
import { AudioProvider } from './Context/AudioContext/AudioContext'
import { PlaybackStatusProvider } from './Context/PlaybackStatusContext/PlaybackStatusContext'
import { TimerProvider } from './Context/TimerContext/TimerContext'
import { LogBox } from 'react-native'
import axios from 'axios'
import useTokens from './Hooks/useTokens'
import { Text } from 'react-native'
import setDefaultProps from 'react-native-simple-default-props'
import { useFonts, BarlowCondensed_400Regular } from '@expo-google-fonts/barlow-condensed'

if (Platform.OS === 'android') {
	UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true)
}

Sentry.init({
	dsn: SENTRY_DSN,
	enableInExpoDevelopment: true,
	debug: false
})

export default function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const [isAdminViewOpen, setIsAdminViewOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isTourModalVisible, setIsTourModalVisible] = useState(false)
	const { getTokens } = useTokens()

	let [fontsLoaded] = useFonts({
		BarlowCondensed_400Regular
	})

	const defaultText = {
		style: [{ fontFamily: 'BarlowCondensed_400Regular' }]
	}

	// usage
	setDefaultProps(Text, defaultText)

	const getUsertype = async () => {
		try {
			const { access } = await getTokens()
			const { data, status } = await axios.get(USERTYPE_URL, {
				headers: { Authorization: `JWT ${access}` }
			})
			if (status === 200) setIsLoggedIn(true)
			else setIsLoggedIn(false)
			setIsLoading(false)
		} catch (ex) {
			setIsLoggedIn(false)
			setIsLoading(false)
		}
	}

	/* -------------------------------------------------------------------------- */
	/*     Checking for user authentication and unloacking screen orientation     */
	/* -------------------------------------------------------------------------- */
	useEffect(() => {
		async function boot() {
			try {
				LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
				/* ---------------- unlock screen orientation on all devices ---------------- */
				if (Platform.OS !== 'web') {
					createDatabaseOnFirstMount()
				}

				getUsertype()
			} catch (error) {
				console.log(error)
				captureException(error)
			}
		}

		boot()

		/* -------------------------------------------------------------------------- */
		/*                              Database connection                           */
		/* -------------------------------------------------------------------------- */
	}, [])

	/* -------------------------------------------------------------------------- */
	/*                    Sentry DSN to catch and report errors                   */
	/* -------------------------------------------------------------------------- */
	const HandleError = error => {
		captureException(error)
	}

	/* -------------------------------------------------------------------------- */
	/*               Custom status bar to use musicmind theme color               */
	/* -------------------------------------------------------------------------- */
	const MyStatusBar = ({ backgroundColor, ...props }) => (
		<View style={{ backgroundColor: backgroundColor }}>
			<StatusBar backgroundColor={backgroundColor} {...props} />
		</View>
	)

	return (
		<ErrorBoundary onError={HandleError}>
			<AuthContext.Provider
				value={{
					isLoggedIn,
					setIsLoggedIn,
					isAdmin,
					setIsAdmin,
					isAdminViewOpen,
					setIsAdminViewOpen,
					isTourModalVisible,
					setIsTourModalVisible
				}}
			>
				<MyStatusBar
					backgroundColor={colors.primary}
					barStyle="light-content"
					showHideTransition={'slide'}
					animated={true}
				/>
				<LayoutProvider>
					<AudioProvider>
						<SoundProvider>
							<PlaybackStatusProvider>
								<TimerProvider>
									<MenuProvider>
										<Suspense fallback={<Loader />}>
											{isLoading ? (
												<Loader />
											) : (
												<NavigationContainer>
													{!isLoggedIn ? (
														<RootStack />
													) : isAdmin && isAdminViewOpen ? (
														<AdminMainStack />
													) : (
														<MainStack />
													)}
												</NavigationContainer>
											)}
										</Suspense>
									</MenuProvider>
								</TimerProvider>
							</PlaybackStatusProvider>
						</SoundProvider>
					</AudioProvider>
				</LayoutProvider>
			</AuthContext.Provider>
		</ErrorBoundary>
	)
}
