import React, { useEffect, useState } from 'react'

import { Alert, Platform, ScrollView, Text, View } from 'react-native'
import * as Sentry from 'sentry-expo'
import Loader from '../../Components/Loader/Loader'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import { PLAYLIST_TRACKS_URL, USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import PlaylistDetails from '../TracksScreen/components/PlaylistDetails'
import TrackList from '../TracksScreen/components/TrackList'

export default function PlaylistTracksScreen({ route, navigation }) {
	const [playlistDetails, setPlaylistDetails] = useState([])
	const { playlist, playlistTitle, playlistPhoto } = route.params ? route.params : global.routeParams
	const [loading, setLoading] = useState(true)
	const api = useAxios()

	const [userPlaylists, setUserPlaylists] = useState([])
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		async function boot() {
			let tracksList = []
			let playlistDetails

			await api
				.get(USER_PLAYLIST_URL)
				.then(({ data }) => {
					data = playlistSortByName(data)
					if (playlist) playlistDetails = data.filter(x => x.id === playlist)[0]
					setUserPlaylists(data)
				})
				.catch(error => {
					console.log(error)
					if (Platform.OS === 'web') Sentry.Browser.captureException(error)
					else Sentry.Native.captureException(error)
				})

			const { data } = await api.get(PLAYLIST_TRACKS_URL + playlist)
			if (data.length > 0) {
				data.map((itemMain, index) => {
					tracksList.push({
						track: itemMain.track,
						Photo: playlistPhoto,
						title: playlistTitle,
						id: itemMain.id
					})
				})
			}
			setPlaylistDetails(tracksList)
			setLoading(false)
		}
		boot()
	}, [playlist])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				{/* {playlistDetails.length > 0 ? ( */}
				<View>
					<View style={{ marginTop: 20 }}>
						<PlaylistDetails
							photo={playlistDetails[0].Photo}
							title={playlistDetails[0].title}
							tracks={playlistDetails.length}
							tracksList={playlistDetails}
							isRepeatAllTracks={true}
							isHideTherapist={true}
							artistName={''}
						/>
					</View>
					<TrackList
						photo={playlistDetails[0].Photo}
						title={playlistDetails[0].title}
						tracksList={playlistDetails}
						key={playlistDetails[0].id}
						navigation={navigation}
						userPlaylists={userPlaylists}
						updateUserPlaylists={updateUserPlaylists}
						openNotifModal={openNotifModal}
						closeNotifModal={closeNotifModal}
						isRepeatAllTracks={true}
					/>
				</View>

				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</ScrollView>
		</View>
	)
}
