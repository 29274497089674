import { Image, ImageBackground, Modal, StyleSheet, Text, View, useWindowDimensions, ScrollView } from 'react-native'
import { useEffect, useState } from 'react'
import { colors } from '../../Helpers/variables'
import NewFeedScreen from '../../Screens/NewsFeedScreen/NewsFeedScreen'
import { SecondaryButton } from '../Buttons/Button'
import { Heading } from '../Typography/Typography'

export const TherapistModal = ({ modalVisible, setModalVisible, info }) => {
	
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '40%'
		} else {
			return '80%'
		}
	}

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: isWideScreen && width > 800 ? 20 : 10,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: isWideScreen && width > 800 ? 35 : 20,
			shadowColor: 'gray',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			minWidth: getModalWidth(),
			maxWidth: getModalWidth(),
			height: '80%'
			// overflow: 'scroll'
			// minWidth: 200
			// alignItems: 'center',
			// justifyContent: 'center',
		},
		label: {
			marginBottom: 20,
			color: 'white',
			fontSize: isWideScreen && width > 800 ? 20 : 18,
			marginTop: isWideScreen && width > 800 ? 10 : 5
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})

	const fallback = require('../../assets/fallback_cat.png')
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<ScrollView style={{ width: '100%' }}>
						<View style={{ justifyContent: 'center', alignItems: 'center' }}>
							<ImageBackground
								blurRadius={20}
								imageStyle={{ borderRadius: isWideScreen && width > 800 ? 15 : 5 }}
								source={info.Photo ? { uri: info.Photo } : fallback}
								style={{ 
									height: isWideScreen && width > 800 ? 200 : 100, 
									width: isWideScreen && width > 800 ? 200 : 100,
									alignItems: 'center',
									justifyContent: 'center',
								}}
								resizeMode={'cover'}
							>
							<Image
									source={info.Photo ? { uri: info.Photo } : fallback}
									style={{
										height: isWideScreen && width > 800 ? 150 : 80,
										width: isWideScreen && width > 800 ? 150 : 80,
										borderRadius: isWideScreen && width > 800 ? 50 : 20,
										borderColor: colors.accent,
										borderWidth: 2
									}}
									resizeMode={'cover'}
								/>
							</ImageBackground>
							<Heading style={{ fontSize: isWideScreen && width > 800 ? 24 : 18 }}>{info.name}</Heading>
							<Text style={{marginTop: isWideScreen && width > 800 ? 30 : 20, fontSize: isWideScreen && width > 800 ? 18 : 14, color: 'white'}}>{info.description}</Text>
						</View>
					</ScrollView>
					<View style={{ 
							justifyContent: isWideScreen && width > 800 ? 'flex-end' : 'center', 
							alignItems: 'center', 
							marginTop: isWideScreen && width > 800 ? 20 : 10
						}}
					>
						<SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} style={{ width: isWideScreen && width > 800 ? 180 : 150, margin: isWideScreen ? 10 : 5 }} />
					</View>
				</View>
			</View>
		</Modal>
	)
}
