import React, { useEffect, useState } from 'react'
import { ImageBackground, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'

const CarouselCardItem = ({ item }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const styles = StyleSheet.create({
		container: {
			backgroundColor: colors.primary,
			borderRadius: 8,
			padding: isWideScreen ? 20 : 10,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 3
			},
			shadowOpacity: 0.29,
			shadowRadius: 4.65,
			elevation: 7,
			opacity: 0.8,
			borderRadius: 15,
			minWidth: isWideScreen ? 700 : isSmallScreen ? 240 : 360,
			maxWidth: isWideScreen ? 700 : isSmallScreen ? 240 : 360,
			marginBottom: isWideScreen ? 20 : 10,
			marginHorizontal: isWideScreen ? 10 : 5
		},
		image: {
			height: '100%'
		},
		header: {
			color: 'white',
			fontSize: isWideScreen ? 24 : 22,
			fontWeight: 'bold'
		},
		body: {
			color: 'white',
			fontSize: isWideScreen ? 18 : 14,
		}
	})
	
	return (
		<View style={styles.container}>
			<ImageBackground source={{ uri: item.imgUrl }} resizeMode="cover" style={styles.image}>
				<ScrollView style={{ width: '100%' }}>
					<Text style={styles.header}>{item.title}</Text>
					<Text style={styles.body}>{item.body}</Text>
				</ScrollView>
			</ImageBackground>
		</View>
	)
}

export default CarouselCardItem
