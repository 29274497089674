import Slider from '@react-native-community/slider'
import React, { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { secondsParser } from '../../../../../Helpers/timeConverter'
import { colors } from '../../../../../Helpers/variables'
import usePlaybackStatus from '../../../../../Hooks/usePlaybackStatus'
import useSound from '../../../../../Hooks/useSound'
import useTimer from '../../../../../Hooks/useTimer'

const SeekControls = () => {
	const { currentPlayingTrack, handleSeek } = useSound()
	const { timerProperties, mediaPlayerTimerInterval } = useTimer()
	const [seekPosition, setSeekPosition] = useState(null)
	const [seekBarPosition, setSeekBarPosition] = useState(0)
	const [totalDuration, setTotalDuration] = useState(null)
	const { playbackStatus } = usePlaybackStatus()

	useEffect(() => {
		const { durationMillis, positionMillis } = playbackStatus
		setTotalDuration(durationMillis)
		setSeekPosition(positionMillis)
		if (positionMillis) {
			setSeekBarPosition(positionMillis / durationMillis)
		}
		if (playbackStatus.isPlaying && timerProperties.isTimerChecked) {
			mediaPlayerTimerInterval()
		}
	}, [playbackStatus])

	return (
		<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
			<Text
				style={{
					color: currentPlayingTrack === null ? colors.secondary : 'white',
					fontSize: 10
				}}
			>
				{secondsParser(seekPosition)}
			</Text>
			<Slider
				style={{ marginHorizontal: 1, width: 170, height: 40 }}
				minimumValue={0}
				maximumValue={1}
				value={seekBarPosition}
				minimumTrackTintColor={colors.accent}
				thumbTintColor={currentPlayingTrack === null ? colors.secondary : colors.accent}
				maximumTrackTintColor={colors.secondary}
				step={0.001}
				thumbStyle={{ width: 15, height: 15 }}
				onSlidingComplete={value => handleSeek(value * playbackStatus.durationMillis)}
			/>
			<Text
				style={{
					color: currentPlayingTrack === null ? colors.secondary : 'white',
					fontSize: 10
				}}
			>
				{secondsParser(totalDuration)}
			</Text>
		</View>
	)
}

export default React.memo(SeekControls)
