import { Image, ImageBackground, Pressable, Text, View, useWindowDimensions } from 'react-native'
import { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { colors } from '../../../Helpers/variables'


const Therapist = ({ therapist, handleViewTherapist, index, ...props }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const fallback = require('../../../assets/fallback_cat.png')
	return (
		<Pressable onPress={() => handleViewTherapist(therapist)}>
			<View
				style={{
					width: isWideScreen && width > 800 ? 200 : 100,
					borderRadius: isWideScreen && width > 800 ? 10 : 5,
					backgroundColor: colors.secondary,
					marginBottom: isWideScreen && width > 800 ? 15 : 5,
					marginHorizontal: 0
				}}
			>
				<ImageBackground
					source={therapist.Photo ? { uri: therapist.Photo } : fallback}
					resizeMode="cover"
					imageStyle={{ borderRadius: isWideScreen && width > 800 ? 10 : 5 }}
					onError={() => console.log('Error loading image ...')}
					style={{
						width: isWideScreen && width > 800 ? 200 : 100,
						height: isWideScreen && width > 800 ? 200 : 100,
						alignItems: 'center',
						justifyContent: 'center',
					}}
					blurRadius={20}
				>
					<Image
						source={therapist.Photo ? { uri: therapist.Photo } : fallback}
						style={{
							height: isWideScreen && width > 800 ? 150 : 80,
							width: isWideScreen && width > 800 ? 150 : 80,
							borderRadius: isWideScreen && width > 800 ? 50 : 20,
							borderColor: colors.accent,
							borderWidth: 2
						}}
						resizeMode={'cover'}
					/>
				</ImageBackground>

				<View style={{ 
						marginVertical: isWideScreen && width > 800 ? 20 : 5, 
						justifyContent: 'center', 
						alignItems: 'center', 
						height: isWideScreen ? 40 : 30 
					}}
				>
					<Text
						style={{
							fontSize: isWideScreen && width > 800 ? 15 : (isSmallScreen ? 10 : 12),
							fontWeight: 'bold',
							color: 'white',
						}}
					>
						{therapist.name}
					</Text>
				</View>
			</View>
		</Pressable>
		
	)
}

export default Therapist
