import { ScrollView, Text, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import useLayout from '../../Hooks/useLayout'
import CategoryCard from '../HomeScreen/components/CategoryCard'

export default function SearchResultContainer({ containers, navigation }) {
	const { deviceType } = useLayout()

	return (
		<View>
			{containers && (
				<View>
					{containers.length > 0 ? (
						<ScrollView style={{ marginLeft: 10 }}>
							<FlatGrid
								data={containers}
								renderItem={({ item }) => (
									<CategoryCard
										wasDataCached={false}
										item={item}
										navigate={navigation.navigate}
										deviceType={deviceType}
									/>
								)}
								ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
								keyExtractor={item => item.id}
								itemDimension={200}
								contentContainerStyle={{ paddingBottom: 90 }}
								style={{ flex: 1 }}
							/>
						</ScrollView>
					) : (
						<Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>
					)}
				</View>
			)}
		</View>
	)
}
