import { Ionicons } from '@expo/vector-icons'
import { useState, lazy, useEffect } from 'react'
import { Linking, Platform, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { MenuDivider } from 'react-native-material-menu'
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { colors } from '../../Helpers/variables'
import useAuth from '../../Hooks/useAuth'

import FeedbackModal from '../Modals/FeedbackModal'
import UserManualModal from '../Modals/UserManualModal'

export const HelpMenu = () => {
	const {width} = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	
	const handleResize = () => {
		setIsWideScreen(width > 700)
	}
	
	useEffect(() => {
		handleResize()
	}, [width])

	const [isOpened, setIsOpened] = useState(false)
	const [userManualModalVisible, setUserManualModalVisible] = useState(false)
	const [feedbackModalVisible, setFeedbackModalVisible] = useState(false)
	const { isTourModalVisible, setIsTourModalVisible } = useAuth()

	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<Menu
				opened={isOpened}
				onBackdropPress={() => {
					setIsOpened(false)
				}}
			>
				<MenuTrigger
					onPress={() => {
						setIsOpened(true)
					}}
				>
					<Ionicons name="help" style={{ marginLeft: 10 }} size={isWideScreen ? 30 : 22} color="white" />
				</MenuTrigger>
				<MenuOptions style={{ backgroundColor: colors.secondary, padding: 15 }}>
					<View>
						<TouchableOpacity
							onPress={async () => {
								if (Platform.OS === 'web')
									window.open('https://instoremusic.dk/Musicmind_UserManual_NativeApp.pdf', '_blank')
								else {
									Linking.openURL('https://instoremusic.dk/Musicmind_UserManual_NativeApp.pdf').catch(err => {
										console.log(err)
									})
								}
								setIsOpened(false)
							}}
						>
							<Text
								style={{
									fontSize: 15,
									color: 'white',
									padding: 10
								}}
							>
								Brugermanual
							</Text>
						</TouchableOpacity>
					</View>
					<MenuDivider color="gray" />
					<View>
						<TouchableOpacity
							onPress={() => {
								setFeedbackModalVisible(true)
								setIsOpened(false)
							}}
						>
							<Text
								style={{
									fontSize: 15,
									color: 'white',
									padding: 10
								}}
							>
								Feedback
							</Text>
						</TouchableOpacity>
					</View>
					{/* Temporarily remove UserManual - 08282024 */}
					{/* <MenuDivider color="gray" />
					<View>
						<TouchableOpacity
							onPress={() => {
								setIsTourModalVisible(true)
								setIsOpened(false)
							}}
						>
							<Text
								style={{
									fontSize: 15,
									color: 'white',
									padding: 10
								}}
							>
								Guidet tur
							</Text>
						</TouchableOpacity>
					</View> */}
				</MenuOptions>
			</Menu>

			{userManualModalVisible && (
				<UserManualModal modalVisible={userManualModalVisible} setModalVisible={setUserManualModalVisible} />
			)}

			{feedbackModalVisible && (
				<FeedbackModal modalVisible={feedbackModalVisible} setModalVisible={setFeedbackModalVisible} />
			)}
		</View>
	)
}
