import React, { useEffect, useState } from 'react'
import useSound from '../../Hooks/useSound'

export const PlaybackStatusContext = React.createContext()

export const PlaybackStatusProvider = ({ children }) => {
	const { currentPlayingTrack, sound, handleTrackChange } = useSound()
	const [playbackStatus, setPlaybackStatus] = useState({
		progressUpdateIntervalMillis: 500,
		positionMillis: 0,
		shouldPlay: false,
		rate: 1.0,
		shouldCorrectPitch: false,
		volume: 1.0,
		isMuted: false,
		isLooping: false,
		isLoaded: false,
		isPlaying: false
	})

	useEffect(() => {
		if (currentPlayingTrack) sound.current.setOnPlaybackStatusUpdate(setPlaybackStatus)
	}, [currentPlayingTrack])

	// Handle track end
	useEffect(() => {
		async function boot() {
			if (currentPlayingTrack) if (playbackStatus.didJustFinish) await handleTrackChange('next')
		}
		boot()
	}, [playbackStatus.didJustFinish, currentPlayingTrack])

	return (
		<PlaybackStatusContext.Provider
			value={{
				playbackStatus
			}}
		>
			{children}
		</PlaybackStatusContext.Provider>
	)
}
