import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import { Pressable, useWindowDimensions } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'
import { useEffect, useState } from 'react'

export default function RepeatButton() {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const { currentPlayingTrack, handleClickRepeat, repeatType } = useSound()

	
	return (
		<Pressable>
			{repeatType === 'one' ? (
				<MaterialIcons
					name="repeat-one"
					size={isWideScreen ? 20 : 18}
					color={currentPlayingTrack === null ? colors.secondary : colors.accent}
					onPress={() => handleClickRepeat('none')}
				/>
			) : (
				<Ionicons
					name="ios-repeat-sharp"
					size={20}
					color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
					onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
				/>
			)}
		</Pressable>
	)
}
