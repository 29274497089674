import React from 'react'
import { useEffect } from 'react'
import { Text, View } from 'react-native'

export default function OnlineBar({ setShowOnlineBar }) {
	useEffect(() => {
		async function boot() {
			let timeoutId = setTimeout(async () => {
				console.log('OnlineBar timeout running')
				setShowOnlineBar(false)
				clearTimeout(timeoutId)
			}, 5000)
		}

		boot()
	}, [])

	return (
		<View style={{ textAlign: 'center', backgroundColor: 'green', width: '100%' }}>
			<Text style={{ color: 'white', fontWeight: 'bold' }}>Forbindelsen genoprettet.</Text>
		</View>
	)
}
