import { Ionicons } from '@expo/vector-icons'
import { Platform, Pressable, Text, View, useWindowDimensions } from 'react-native'
import * as Sentry from 'sentry-expo'
import { useEffect, useState } from 'react'
import { colors } from '../../Helpers/variables'
import useAudio from '../../Hooks/useAudio'
import useAuth from '../../Hooks/useAuth'
import useTimer from '../../Hooks/useTimer'
import AuthService from '../../Services/AuthService'
import { ConfirmationModal } from '../Modals/ConfirmationModal'

export default function Header({ navigation, title }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const { setIsLoggedIn, setIsAdminViewOpen } = useAuth()
	const [logoutConfirmModal, setLogoutConfirmModal] = useState(false)

	const { sound } = useAudio()
	const { clearTimeoutId } = useTimer()

	const handleAdminView = () => {
		setIsAdminViewOpen(false)
	}

	const handleLogout = async () => {
		AuthService.logout()

		try {
			await sound.current.unloadAsync()
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
		setIsLoggedIn(false)
		setIsAdminViewOpen(false)
		clearTimeoutId()
	}

	const cancelLogoutConfirmModal = () => {
		setLogoutConfirmModal(false)
	}

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				backgroundColor: colors.primary,
				paddingVertical: 10,
				paddingRight: 5
			}}
		>
			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginTop: 5 }}>
				<View style={{ flexDirection: 'row' }}>
					{navigation.canGoBack() ? (
						<Pressable onPress={() => navigation.goBack()}>
							<Ionicons name="arrow-back" size={isWideScreen ? 26: 22} style={{ marginRight: 10 }} color="white" />
						</Pressable>
					) : (
						<Pressable onPress={handleAdminView}>
							<Ionicons name="arrow-back" size={isWideScreen ? 26: 22} style={{ marginRight: 10 }} color="white" />
						</Pressable>
					)}
					<Text style={{ color: 'white', fontSize: 20 }}>{title}</Text>
				</View>
				<View style={{ flexDirection: 'row' }}>
					<Pressable onPress={handleAdminView}>
						<Ionicons name="home" style={{ marginLeft: 20 }} size={isWideScreen ? 30 : 22} color="white" />
					</Pressable>
					<Pressable
						onPress={() => {
							setLogoutConfirmModal(true)
						}}
					>
						<Ionicons name="power" style={{ marginLeft: 20 }} size={isWideScreen ? 30 : 22} color="white" />
					</Pressable>
				</View>
			</View>
			{logoutConfirmModal && (
				<ConfirmationModal
					handleSubmit={handleLogout}
					modalVisible={logoutConfirmModal}
					cancelCallback={cancelLogoutConfirmModal}
					description={'Er du sikker på du ønsker at logge ud af Musicimind?'}
					titleCancel={'Nej'}
					titleSubmit={'Ja'}
				/>
			)}
		</View>
	)
}
