import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import { colors } from '../../Helpers/variables'
import LoginScreen from '../../Screens/LoginScreen/LoginScreen'

const Stack = createNativeStackNavigator()

export default function RootStack() {
	return (
		<Stack.Navigator
			screenOptions={{
				headerShown: false,
				contentStyle: { backgroundColor: colors.primary }
			}}
		>
			<Stack.Screen name="LoginScreen" component={LoginScreen} />
		</Stack.Navigator>
	)
}
