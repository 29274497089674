import AsyncStorage from '@react-native-async-storage/async-storage'

export async function secureStorageSetValues(key, name, value) {
	const result = await secureStorageGetValue(key)
	let newItems = []
	if (result) {
		newItems = result
		if (newItems.filter(x => x.name === name).length === 0)
			newItems.push({
				name: name,
				value: value,
				subTitle: key === 'username' ? '**********' : value
			})
	} else
		newItems.push({
			name: name,
			value: value,
			subTitle: key === 'username' ? '**********' : value
		})
	await secureStorageSetValue(key, newItems)
}

export async function secureStorageGetValueFromList(key, name) {
	const result = await secureStorageGetValue(key)
	if (result) {
		if (result.filter(x => x.name === name).length > 0) return result.filter(x => x.name === name)[0].value
		else return undefined
	} else return []
}

export async function secureStorageSetValue(key, value) {
	await AsyncStorage.setItem(key, JSON.stringify(value))
}

export async function secureStorageGetValue(key) {
	const result = await AsyncStorage.getItem(key)
	if (result) return JSON.parse(result)
	else return undefined
}

export async function secureStorageDeleteValue(key) {
	await AsyncStorage.deleteItem(key)
}
