import { ImageBackground, StyleSheet, Text, View , useWindowDimensions} from 'react-native'
import { colors } from '../../../Helpers/variables'
import { useEffect, useState } from 'react'

export default function TrackCard({ image, title, secondTitle = '', number = 10 }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	const styles = StyleSheet.create({
		title: {
			color: 'white',
			fontSize: isWideScreen ? 18 : 14,
			fontWeight: 'bold',
			textAlign: 'center',
			marginTop: 'auto',
			marginBottom: 'auto',
			overflow: 'hidden'
		},
		secondTitle: {
			color: '#F4F4F4',
			fontSize: 10,
			fontWeight: 'bold',
			textAlign: 'center',
			marginTop: 'auto',
			marginBottom: 'auto',
			overflow: 'hidden',
		}
	})


	const fallback = require('../../../assets/fallback_cat.png')

	return (
		<View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<ImageBackground
				source={image ? { uri: image } : fallback}
				resizeMode="cover"
				blurRadius={image ? 0 : 10}
				imageStyle={{ borderRadius: 15 }}
				style={{ 
					height: isWideScreen ? 130 : isSmallScreen ? 100 : 120, 
					width: isWideScreen ? 130 : isSmallScreen ? 100 : 120 
				}}
			>
				<View style={{ 
						width: isWideScreen ? 25 : 20, 
						height: isWideScreen ? 25 : 20, 
						backgroundColor: colors.accent, 
						borderRadius: 5
					}}
				>
					<Text style={{ color: 'white', textAlign: 'center', fontSize: isWideScreen ? 18 : 16, fontWeight: 'bold' }}>{number}</Text>
				</View>
			</ImageBackground>
			<View style={{ width: 100 }}>
				<Text style={styles.title} ellipsizeMode={'tail'}
					numberOfLines={1}>{title}</Text>
				{secondTitle !== '' && <Text style={styles.secondTitle} ellipsizeMode={'tail'}
					numberOfLines={1}>{secondTitle}</Text>}
			</View>
		</View>
	)
}
