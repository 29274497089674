import { Text, useWindowDimensions, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'

export default function RefreshButton({ setShouldRefresh }) {
	const { height } = useWindowDimensions()

	return (
		<View
			style={{ backgroundColor: colors.primary, height: height - 80, alignItems: 'center', justifyContent: 'center' }}
		>
			<View>
				<Text style={{ textAlign: 'center', color: 'white' }}>
					Der opstod en uventet fejl. Tjek din internetforbindelse, eller prøv igen.
				</Text>
				<PrimaryButton onPress={() => setShouldRefresh(prev => !prev)} title={'Prøv igen'} />
			</View>
		</View>
	)
}
