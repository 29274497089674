import { FlatList, Pressable, Text, View } from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import { colors } from '../../Helpers/variables'
import Card from './components/Card'

export default function SearchResultPlaylist({ playlist, navigation, title, isShowNoDataLabel = true }) {
	if (playlist) {
		if (playlist.length === 0) {
			return null
		}
	}

	return (
		<View>
			{playlist && (
				<View>
					<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
						{title}
					</Text>
					{playlist.length > 0 ? (
						<View>
							<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={playlist}
									renderItem={({ item, index }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'center',
												height: 165,
												margin: 15
											}}
										>
											<Pressable
												onPress={() =>
													navigation.navigate('PlaylistTracks', {
														playlist: item.playlist ? item.playlist : item.id,
														playlistTitle: item.playlist_name ? item.playlist_name : item.name,
														playlistPhoto: item.Photo
													})
												}
												key={(item.playlist ? item.playlist : item.id) + uuidv4}
											>
												<Card image={item.Photo} title={item.playlist_name ? item.playlist_name : item.name} />
											</Pressable>
										</View>
									)}
									keyExtractor={item => item.id}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					) : (
						<Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>{'Ingen data...'} </Text>
					)}
				</View>
			)}
		</View>
	)
}
