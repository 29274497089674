import { useIsFocused } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import WebView from 'react-native-webview'
import Loader from '../../Components/Loader/Loader'

const styles = StyleSheet.create({
	container: {
		flex: 1
	}
})

export default function UserManualScreen({ navigation }) {
	const [loading, setLoading] = useState(true)
	const isFocused = useIsFocused()

	useEffect(() => {
		setLoading(false)
	}, [isFocused])

	return loading ? (
		<Loader />
	) : (
		<WebView
			style={styles.container}
			source={require('../../assets/Musicmind_UserManual.pdf')}
			startInLoadingState={true}
		/>
	)
}
