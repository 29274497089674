import { Modal, StyleSheet, View, useWindowDimensions } from 'react-native'
import { useEffect, useState } from 'react'
import { colors } from '../../Helpers/variables'
import NewFeedScreen from '../../Screens/NewsFeedScreen/NewsFeedScreen'
import { SecondaryButton } from '../Buttons/Button'

const NewsFeedModal = ({ modalVisible, setModalVisible, newsFeed }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '40%'
		} else {
			return '80%'
		}
	}

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: isWideScreen ? 20 : 10,
			shadowColor: 'gray',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			width: getModalWidth()
		},
		label: {
			marginBottom: isWideScreen ? 20 : 18,
			color: 'white',
			fontSize: 20
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<NewFeedScreen newsFeed={newsFeed} />
					<View style={{ alignItems: 'center' }}>
						<SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} style={{ width: isWideScreen ? 180 : 150, margin: 5 }} />
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default NewsFeedModal
