import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ScrollView, Text, useWindowDimensions, View } from 'react-native'
import base64 from 'react-native-base64'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { TextArea } from '../../Components/Forms/Input'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { Heading } from '../../Components/Typography/Typography'
import { FEEDBACKREQUEST_URL } from '../../Helpers/variables'
import { Platform, Alert } from 'react-native'
import useAxios from '../../Hooks/useAxios'

export default function FeedbackScreen({ setModalVisible }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const [messageInput, setMessageInput] = useState('')
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const api = useAxios()

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const createJiraIssue = () => {
		const bodyData = {
			fields: {
				project: {
					key: 'LFA'
				},
				summary: 'TEST 99',
				description: 'REST APIs are great.',
				issuetype: {
					name: 'Bug'
				},
				sprint: {
					name: 'LFA Sprint 1'
				}
			}
		}

		axios
			.post(
				'https://www.musicmind-cors.dk/https://auditive.atlassian.net/rest/api/2/issue/',
				JSON.stringify(bodyData),
				{
					headers: {
						Authorization: `Basic${base64.encode('annborreo@instorex.dk:THD87cBueEnrXTUGtosPF094').toString('base64')}`,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						'X-Atlassian-Token': 'no-check'
					}
				}
			)
			.then(response => {
				console.log(response.status)
				if (response.status === 201) {
					console.log('success: ' + item.name)
				} else {
					console.log('failed: ' + item.name)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true)
		// createJiraIssue()
		if (messageInput === '') {
			setErrorMessage('Besked er påkrævet')
			setLoading(false)
			return
		}

		try {
			let message = messageInput

			let { status: status } = await api.post(FEEDBACKREQUEST_URL, {
				message
			})

			if (status === 201) {
				openNotifModal('Tak for din feedback som er modtaget', 'Email sendt!')
				setMessageInput('')
			} else openNotifModal('Tak for din feedback som er modtaget', 'Email sendt!')
		} catch (err) {
			console.log(err)
		} finally {
			setErrorMessage('')
			setLoading(false)
		}
	}

	return (
		<View>
			<Heading>Feedback</Heading>
			<View style={{ width: '100%', marginTop: 10}}>
				<TextArea
					onChangeText={text => setMessageInput(text)}
					placeholder={'Besked'}
					value={messageInput}
					numberOfLines={4}
				/>
			</View>
			<Text style={{ fontSize: 14, color: 'red' }}>{errorMessage && errorMessage}</Text>
			<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
				{setModalVisible && (
					<SecondaryButton 
						title={'Luk'} 
						onPress={() => setModalVisible(false)} 
						style={{ 
							width: '50%', 
							minHeight: isWideScreen ? 45 : 35, 
							flexDirection: 'row', 
							alignItems: 'center', 
							justifyContent: 'center', 
							marginRight: 5
						}}
					/>
				)}
				<PrimaryButton 
					onPress={handleFormSubmit} 
					title={'Send'} 
					loading={loading} 
					style={{ width: '50%', minHeight: isWideScreen ? 45 : 35 }} 
				/>
			</View>

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={setModalVisible}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</View>
	)
}
