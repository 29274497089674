import React, { useEffect, useState } from 'react'
import * as Sentry from 'sentry-expo'

import { useNavigation, useRoute } from '@react-navigation/native'
import { Alert, Platform, ScrollView, Text, View } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import { ARTIST_TRACKS_URL, colors, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import PlaylistDetails from '../TracksScreen/components/PlaylistDetails'
import TrackList from '../TracksScreen/components/TrackList'

const ArtistTracksScreen = () => {
	const [artistDetails, setArtistDetails] = useState(null)
	const route = useRoute()
	const navigation = useNavigation()
	const { artistId } = route.params ? route.params : global.routeParams
	const [loading, setLoading] = useState(true)
	const api = useAxios()
	const [userPlaylists, setUserPlaylists] = useState([])
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		async function boot() {
			api
				.get(USER_PLAYLIST_URL)
				.then(({ data }) => {
					data = playlistSortByName(data)
					setUserPlaylists(data)
				})
				.catch(error => {
					console.log(error)
					if (Platform.OS === 'web') Sentry.Browser.captureException(error)
					else Sentry.Native.captureException(error)
				})

			const { data } = await api.get(ARTIST_TRACKS_URL + artistId)
			let tempMainAlbum = []
			if (data.length > 0) {
				data[0].mm_albums.map(itemMain => {
					let tracksList = []
					let tempMain = itemMain
					itemMain.mm_tracks.map(item => {
						tracksList.push({
							track: item,
							Photo: itemMain.Photo,
							title: itemMain.album,
							id: item.id
						})
					})
					tempMain.mm_tracks = tracksList
					tempMainAlbum.push(tempMain)
				})
			}
			setArtistDetails(tempMainAlbum || null)
			setLoading(false)
		}
		boot()
	}, [artistId])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				{artistDetails[0] && (
					<View>
						{artistDetails[0].mm_tracks.length > 0 && (
							<Text style={{ fontSize: 30, color: 'white', marginBottom: 20, fontWeight: 'bold' }}>
								{artistDetails[0].mm_tracks[0].artist_name}
							</Text>
						)}
					</View>
				)}
				{artistDetails &&
					artistDetails.map((item, index) => (
						<View key={index} style={{ backgroundColor: colors.background, flex: Platform.OS === 'web' ? undefined : 1 }}>
							{item.mm_tracks.length > 0 && (
								<View>
									<View style={{ marginTop: 20 }}>
										<PlaylistDetails
											photo={item.Photo}
											title={item.album}
											tracks={item.mm_tracks.length}
											tracksList={item.mm_tracks}
											isHideTherapist={true}
											artistName={item.mm_tracks[0].track.artist_name}
										/>
									</View>
									<TrackList
										photo={item.Photo}
										title={item.title}
										tracksList={item.mm_tracks}
										key={item.id}
										navigation={navigation}
										userPlaylists={userPlaylists}
										updateUserPlaylists={updateUserPlaylists}
										openNotifModal={openNotifModal}
										closeNotifModal={closeNotifModal}
										isRepeatAllTracks={false}
										isMultipleTrackList={true}
									/>
								</View>
							)}
						</View>
					))}
				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</ScrollView>
		</View>
	)
}

export default React.memo(ArtistTracksScreen)
