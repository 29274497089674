import { Ionicons } from '@expo/vector-icons'
import { Pressable, useWindowDimensions } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'
import { useEffect, useState } from 'react'

export default function ShuffleButton() {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const { currentPlayingTrack, handleClickShuffle, isShuffleActive } = useSound()
	
	return (
		<Pressable disabled={currentPlayingTrack === null}>
			<Ionicons
				name="shuffle"
				size={isWideScreen ? 20 : 18}
				color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
				onPress={() => handleClickShuffle()}
			/>
		</Pressable>
	)
}
