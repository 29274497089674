import axios from 'axios'
import { Platform } from 'react-native'
import { BASE_API_URL, REFRESH_URL } from '../Helpers/variables'
import AuthService from '../Services/AuthService'
import useAuth from './useAuth'
import useTokens from './useTokens'
import * as Sentry from 'sentry-expo'

const useAxios = () => {
	const { getTokens, setTokens } = useTokens()
	const { setIsLoggedIn, setIsAdminViewOpen } = useAuth()
	const api = axios.create({
		baseURL: BASE_API_URL
	})

	api.interceptors.request.use(
		async config => {
			const { access } = await getTokens()
			// console.log(config.url + access)
			if (!config.headers['Authorization']) config.headers['Authorization'] = `JWT ${access}`
			return config
		},
		error => {
			console.log(error)
			return Promise.reject(error)
		}
	)

	api.interceptors.response.use(
		response => response,
		async error => {
			const { response, config } = error
			const { refresh } = await getTokens()
			const prevRequest = config
			if (response.status === 401 && response.data.code === 'token_not_valid') {
				prevRequest.sent = true
				const d = new FormData()
				d.append('refresh', refresh)
				axios
					.post(REFRESH_URL, d)
					.then(
						({ data }) => {
							setTokens(data)
							prevRequest.headers['Authorization'] = `JWT ${data.access}`
							return api(prevRequest)
						},
						err => {
							AuthService.logout()
							setIsLoggedIn(false)
							setIsAdminViewOpen(false)
							return Promise.reject(err)
						}
					)
					.catch(err => {
						if (Platform.OS === 'web') Sentry.Browser.captureException(err)
						else Sentry.Native.captureException(err)
					})
			}
			return Promise.reject(error)
		}
	)

	return api
}

export default useAxios
