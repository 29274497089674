import { useEffect, useState } from 'react'
import { FlatList, Text, View, useWindowDimensions } from 'react-native'
import { PlaylistCard } from '../../Components/Playlists/PlaylistCards'
import { WHATS_NEW_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import CategoryCard from '../HomeScreen/components/CategoryCard'

export default function WhatsNewScreen({ type, navigation, deviceType, id = 0, handlePlaylistPress }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

    const [items, setItems] = useState([])
    const api = useAxios()

    useEffect(() => {
        const boot = async () => {
            const { data } = await api.get(WHATS_NEW_URL + type + '/' + id)
            setItems(data)
        }

        boot()
    }, [])

    return (
        <View style={{ marginBottom: 0 }}>
            {items && (
                <View>
                    {items.length > 0 && (
                        <View>
                            <View style={{ padding: 10, marginBottom: 10 }}>
                                <Text style={{ color: colors.accent, fontSize: 22 }}>Hvad er nyt?</Text>
                            </View>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                <FlatList
                                    data={items}
                                    renderItem={({ item, index }) => (
                                        <View>
                                            {
                                                type === 'playlistcontainers' &&
                                                <CategoryCard
                                                    wasDataCached={false}
                                                    item={item}
                                                    navigate={navigation.navigate}
                                                    deviceType={deviceType}
                                                    newWidth={isSmallScreen ? 100 : (isWideScreen ? 130 : 120)}
                                                />
                                            }

                                            {
                                                type === 'playlistgroups' &&
                                                <PlaylistCard
                                                    wasDataCached={false}
                                                    item={item}
                                                    width={isSmallScreen ? 100 : (isWideScreen ? 130 : 120)}
                                                    handlePlaylistPress={handlePlaylistPress}
                                                />
                                            }
                                        </View>
                                    )}
                                    keyExtractor={item => item.id}
                                    //if the user pulls from the top of the screen, log out to the console:
                                    onRefresh={() => console.log('refreshing')}
                                    //if set to true, the UI will show a loading indicator and trigger data.
                                    refreshing={false}
                                    horizontal={true}
                                />
                            </View>
                        </View>
                    )}
                </View>
            )}


        </View>
    )
}
