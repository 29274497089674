import { Alert, Modal, StyleSheet, Text, Pressable, View } from 'react-native'
import { useState } from 'react'
import { Input } from '../../../../Forms/Input'
import { colors } from '../../../../../Helpers/variables'
import { convertTimeWithSecs } from '../../../../../Helpers/timeConverter'

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: 200
	},
	button: {
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		margin: 5,
		minWidth: 70
	},
	buttonOpen: {
		backgroundColor: colors.accent
	},
	buttonClose: {
		backgroundColor: colors.primary
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		textAlign: 'center',
		color: 'white'
	},
	rowCenterView: {
		flexDirection: 'row',
		alignItems: 'center'
	}
})

const TimerModal = ({ setModalVisible, modalVisible, setTimerProperties, timerProperties }) => {
	const [timerInput, setTimerInput] = useState(timerProperties.timerMinutes)
	const [errorMessage, setErrorMessage] = useState('')
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(prev => !prev)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.rowCenterView}>
						<Input
							style={{ width: 160 }}
							onChangeText={text => {
								setTimerInput(text)
								if (text === '') {
									setErrorMessage('')
									return
								}
								let tempInput = parseInt(text)
								if (isNaN(tempInput)) setErrorMessage('Timeren skal være numerisk')
								else if (tempInput < 1) setErrorMessage('Timeren skal være 1 minut eller mere')
								else setErrorMessage('')
							}}
							placeholder={'Minutter'}
							keyboardType={'numeric'}
							value={timerInput}
						/>
					</View>
					{errorMessage !== '' && (
						<View style={styles.rowCenterView}>
							<Text style={{ fontSize: 11, color: 'red', width: 150 }}>{errorMessage}</Text>
						</View>
					)}
					<View style={styles.rowCenterView}>
						<Pressable
							style={[styles.button, styles.buttonOpen]}
							onPress={() => {
								let tempInput = parseInt(timerInput)
								if (isNaN(tempInput)) return
								else if (tempInput < 1) return

								setModalVisible(prev => !prev)
								setTimerProperties({
									timerMinutes: timerInput === '' ? 0 : parseInt(timerInput),
									timerCountSeconds: 0,
									timerMinutesOriginal: timerProperties.timerMinutesOriginal,
									isLoaded: timerProperties.isLoaded,
									isTimerChecked: timerProperties.isTimerChecked,
									timerId: timerProperties.timerId?.current,
									isTimerOnCountdown: timerProperties.isTimerOnCountdown,
									countdownTimer: convertTimeWithSecs(timerInput === '' ? 0 : parseInt(timerInput), 0)
								})
							}}
						>
							<Text style={styles.textStyle}>OK</Text>
						</Pressable>
						<Pressable style={[styles.button, styles.buttonClose]} onPress={() => setModalVisible(prev => !prev)}>
							<Text style={styles.textStyle}>Anuller</Text>
						</Pressable>
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default TimerModal
