import { useState } from 'react'
import { TextInput } from 'react-native'
import { colors } from '../../Helpers/variables'

export const Input = ({ style, keyboardType = 'default', ...props }) => {
	const [border, setBorder] = useState(colors.primary)
	return (
		<TextInput
			style={{
				borderColor: border,
				backgroundColor: colors.secondary,
				borderWidth: 2,
				borderRadius: 5,
				paddingVertical: 10,
				paddingHorizontal: 20,
				marginBottom: 10,
				color: 'white',
				...style
			}}
			underlineColorAndroid="transparent"
			onFocus={() => setBorder(colors.accent)}
			onBlur={() => setBorder(colors.primary)}
			placeholderTextColor={'#cdcdcd'}
			autoCapitalize="none"
			clearButtonMode="while-editing"
			keyboardType={keyboardType}
			{...props}
		/>
	)
}

export const TextArea = ({ style, maxLength, ...props }) => {
	const [border, setBorder] = useState(colors.accent)
	return (
		<TextInput
			style={{
				borderColor: border,
				backgroundColor: colors.secondary,
				borderWidth: 2,
				borderRadius: 10,
				paddingVertical: 8,
				paddingHorizontal: 20,
				marginVertical: 10,
				color: 'white',
				...style
			}}
			multiline
			underlineColorAndroid="transparent"
			onFocus={() => setBorder(colors.accent)}
			onBlur={() => setBorder(colors.primary)}
			placeholderTextColor={'#cdcdcd'}
			autoCapitalize="none"
			maxLength={maxLength}
			{...props}
		/>
	)
}
