import { useEffect, useState } from 'react'
import { Animated, Keyboard, KeyboardAvoidingView, Text, View, useWindowDimensions } from 'react-native'

import { PrimaryButton } from '../../Components/Buttons/Button'
import { CheckboxTicker } from '../../Components/Forms/CheckboxTicker'

import { Input } from '../../Components/Forms/Input'
import { SearchableSelectMenu } from '../../Components/Forms/SearchableSelectMenu'
import {
	secureStorageGetValue,
	secureStorageGetValueFromList,
	secureStorageSetValues
} from '../../Helpers/localStorage'

import 'react-native-get-random-values'
import { colors } from '../../Helpers/variables'
import useAuth from '../../Hooks/useAuth'
import AuthService from '../../Services/AuthService'

export default function LoginScreen() {
	const { height } = useWindowDimensions()
	const { setIsLoggedIn, isLoggedIn, setIsTourModalVisible } = useAuth()

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)
	const [isRememberLoginState, setIsRememberLoginState] = useState(false)
	const [rememberedUsers, setRememberedUsers] = useState([])

	let keyboardHeight = new Animated.Value(0)
	let imageHeight = new Animated.Value(150)

	useEffect(() => {
		Keyboard.addListener('keyboardWillShow', keyboardWillShow)
		Keyboard.addListener('keyboardWillHide', keyboardWillHide)

		return () => {
			Keyboard.removeAllListeners('keyboardWillShow')
			Keyboard.removeAllListeners('keyboardWillHide')
		}
	}, [])

	const getRememberedUsers = async () => {
		const users = await secureStorageGetValue('username')
		let newUsers = []
		if (users) {
			users.map(item => {
				newUsers.push({
					name: item.name,
					subTitle: item.subTitle
				})
			})
			setRememberedUsers(newUsers)
		}
	}

	const keyboardWillShow = event => {
		Animated.parallel([
			Animated.timing(keyboardHeight, {
				duration: event.duration,
				toValue: 30,
				useNativeDriver: false
			}),
			Animated.timing(imageHeight, {
				duration: event.duration,
				toValue: 150,
				useNativeDriver: false
			})
		]).start()
	}

	const keyboardWillHide = event => {
		Animated.parallel([
			Animated.timing(keyboardHeight, {
				duration: event.duration,
				toValue: 0,
				useNativeDriver: false
			}),
			Animated.timing(imageHeight, {
				duration: event.duration,
				toValue: 150,
				useNativeDriver: false
			})
		]).start()
	}

	const handleLogin = e => {
		e.preventDefault()
		setMessage('')
		setLoading(true)

		if (username && password) {
			AuthService.login(username, password).then(
				() => {
					setLoading(false)
					setIsLoggedIn(true)
					if (isRememberLoginState) {
						secureStorageSetValues('username', username, password)
					}
				},
				({ response }) => {
					setLoading(false)
					setMessage(response.data.detail)
				}
			)
		} else {
			setLoading(false)
			setMessage('Venligst indtast brugernavn og adgangskode!')
		}
	}

	const handleCheckboxClick = isChecked => {
		console.log('isChecked', isChecked)
		setIsRememberLoginState(!isChecked)
	}

	const handleUsernameChange = async item => {
		setUsername(item)
		await getRememberedUsers()
	}

	const handleDropdownClick = async item => {
		setUsername(item)
		setPassword(await secureStorageGetValueFromList('username', item))
	}

	return (
		<View
			style={{
				height: height,
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<KeyboardAvoidingView behavior={'padding'}>
				<Animated.View
					style={{
						height: height,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: colors.primary,
						paddingBottom: keyboardHeight
					}}
				>
					<View style={{ width: 300 }}>
						<Animated.Image
							source={require('../../assets/music_mind_logo.png')}
							style={{ width: 300, height: imageHeight }}
							resizeMode="contain"
						/>
						<SearchableSelectMenu
							dataSource={rememberedUsers}
							handleDropdownClick={handleDropdownClick}
							placeholder={'Brugernavn'}
							handleChange={async text => {
								handleUsernameChange(text)
							}}
							searchInput={username}
							setSearchInput={setUsername}
						/>
						<Input onChangeText={text => setPassword(text)} value={password} placeholder={'Kodeord'} secureTextEntry />
						<View style={{ marginTop: 20, marginBottom: 20 }}>
							<CheckboxTicker
								isChecked={isRememberLoginState}
								label={'Husk adgangskoden'}
								handleCheckboxClick={handleCheckboxClick}
							/>
						</View>
						<PrimaryButton onPress={handleLogin} title={'Log ind'} loading={loading} />
						<Text style={{ color: 'red', textAlign: 'center', marginVertical: 10 }}>{message}</Text>
					</View>
				</Animated.View>
			</KeyboardAvoidingView>
		</View>
	)
}
