import { Ionicons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { Platform, Pressable, Text, useWindowDimensions, View } from 'react-native'
import { ImportFromThirdPartyModal } from '../../../../Components/Modals/ImportFromThirdPartyModal'
import useLayout from '../../../../Hooks/useLayout'
import NewItemLabel from '../../NewItemLabel'

const LinkButton = ({ route, routeParams, title, icon, open, isModal = false, newItemLabelName = undefined, ...props }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [showModal, setShowModal] = useState(false)
	const navigation = useNavigation()
	const { deviceType } = useLayout()

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const cancelCallback = () => {
		setShowModal(false)
	}

	return (
		<Pressable
			onPress={() => isModal ? setShowModal(isModal) : navigation.navigate(route, routeParams)}
			style={{ paddingVertical: 10, paddingHorizontal: 0 }}
			{...props}
		>
			<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
				<Ionicons name={icon} size={deviceType === 1 ? (isSmallScreen ? 20 : 24) : 24} color={'#ffffff'} />
				{open && (
					<View style={{ flexDirection: 'row', flex: 1 }}>
						<Text style={{
							fontSize: isSmallScreen ? 14 : 16, color: '#ffffff', marginLeft: 5,
							marginRight: width > 700 && width < 780 ? 0 : 5,
						}}>
							{title}
						</Text>
						{
							newItemLabelName &&
							<NewItemLabel newItemLabelName={newItemLabelName} fontSize={isSmallScreen ? 12 : 14} />
						}
					</View>
				)}
			</View>
			{showModal && (
				<ImportFromThirdPartyModal
					cancelCallback={cancelCallback}
				/>
			)}
		</Pressable>
	)
}

export default LinkButton
