import { FlatList, Pressable, Text, View } from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import Card from './components/Card'

export default function SearchResultGenre({ genre, navigation }) {
	return (
		<View>
			{genre && (
				<View>
					{genre.length > 0 ? (
						<View>
							<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								{/* {genre.map(item => (
									<Pressable onPress={() => navigation.navigate('GenreTracks', { genre: item })} key={item + uuidv4}>
										<Card image={item.Photo} title={item} />
									</Pressable>
								))} */}
								<FlatList
									data={genre}
									renderItem={({ item }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'center',
												height: 165,
												margin: 15
											}}
										>
											<Pressable onPress={() => navigation.navigate('GenreTracks', { genre: item })} key={item + uuidv4}>
												<Card image={item.Photo} title={item} />
											</Pressable>
										</View>
									)}
									keyExtractor={item => item.id}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					) : (
						<Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>
					)}
				</View>
			)}
		</View>
	)
}
