import { useEffect, useState } from 'react'
import { ImageBackground, Platform, Pressable, Text, View } from 'react-native'
import uuid from 'react-native-uuid'
import truncateStr from '../../Helpers/truncateStr'
import { colors } from '../../Helpers/variables'
import useFiles from '../../Hooks/useFiles'
import { DeleteButton, InfoButton } from '../Buttons/Button'
import { Popup } from './Popup'

export const PlaylistCard = ({
	item,
	wasDataCached,
	handlePlaylistPress,
	width,
	setRemoveCopyPlaylistConfirmModal,
	setItemCopyPlaylistId,
	hasDeleteButton = false
}) => {
	const playlist = item.item ? item.item : item
	const { getPhoto, isImage } = useFiles()

	const [image, setImage] = useState(playlist.Photo)
	const fallback = require('../../assets/fallback_cat.png')

	useEffect(() => {
		if (Platform.OS !== 'web' && wasDataCached) {
			if (isImage(playlist.Photo)) {
				setImage(getPhoto(playlist.Photo))
			} else {
				setImage(null)
			}
		}
	}, [])

	const [modalVisible, setModalVisible] = useState(false)
	return (
		<Pressable
			onPress={() =>
				handlePlaylistPress(
					playlist.playlist || playlist.id,
					image,
					playlist.playlist_name || playlist.name,
					playlist.music_therapist_name,
					playlist.music_therapist
				)
			}
		>
			<View
				style={{
					width: width,
					borderRadius: 10,
					backgroundColor: colors.secondary,
					marginBottom: 5,
					marginHorizontal: 10,
					minHeight: 220
					// height: 250
				}}
			>
				<ImageBackground
					source={image ? { uri: image } : fallback}
					resizeMode="cover"
					imageStyle={{ borderRadius: 10 }}
					onError={() => {
						// setImage(fallback)
					}}
					style={{
						// width: width,
						height: width
					}}
				>
					{playlist.description && <InfoButton setModalVisible={setModalVisible} />}
					{hasDeleteButton && (
						<View
							style={{
								alignItems: 'flex-end',
								justifyContent: 'flex-end'
							}}
						>
							<DeleteButton
								onPress={() => {
									setItemCopyPlaylistId(playlist.id)
									setRemoveCopyPlaylistConfirmModal(true)
								}}
								key={uuid.v4()}
							/>
						</View>
					)}
				</ImageBackground>

				<View style={{ paddingHorizontal: 5, marginVertical: 15 }}>
					<Text style={{ color: 'white', textAlign: 'center', margin: 5 }}>
						{/* {truncateStr(playlist.playlist_name || playlist.name, 80)} */}
						{playlist.playlist_name || playlist.name}
					</Text>
				</View>
				{modalVisible && (
					<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={playlist.description} />
				)}
			</View>
		</Pressable>
	)
}
