import { useEffect, useState } from 'react'
import { FlatList, Platform, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import * as Sentry from 'sentry-expo'

import Loader from '../../Components/Loader/Loader'
import { AssignPlaylistToUserModal } from '../../Components/Modals/AssignPlaylistToUserModal'
import User from '../../Components/Users/User'

import { colors, USERLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function PlaylistToUser({ navigation }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	const api = useAxios()
	const [userList, setUserList] = useState([])
	const [loading, setLoading] = useState(true)
	const [isModalOpen, setModalOpen] = useState(false)
	const [username, setUsername] = useState('')
	const [userid, setUserid] = useState(0)

	const handleEditUser = item => {
		setModalOpen(true)
		setUsername(item.username)
		setUserid(item.id)
	}

	const getUserList = () => {
		api
			.get(USERLIST_URL)
			.then(({ data }) => {
				setUserList(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getUserList()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				<FlatList
					data={userList}
					ListEmptyComponent={
						<View>
							<Text style={{ color: 'white', marginTop: isWideScreen ? 30 : 20, fontSize: isWideScreen ? 20 : 18 }}>No users...</Text>
						</View>
					}
					renderItem={({ item }) => (
						<User user={item} handleEditUser={handleEditUser} buttonTitle={'Tildel spilleliste'} />
					)}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					keyExtractor={item => item.id}
					contentContainerStyle={{ paddingBottom: 80 }}
				/>

				{isModalOpen && (
					<AssignPlaylistToUserModal
						setModalOpen={setModalOpen}
						isModalOpen={isModalOpen}
						userList={userList}
						username={username}
						userid={userid}
					/>
				)}
			</ScrollView>
		</View>
	)
}
